<template>
<div 
    contenteditable="true"
    v-once v-html="value"
    :value="value"
    @input="$emit('input', $event.target.innerHTML)"
    @paste="onPaste">
    </div>
</template>

<script>
export default {
    props: ['value'],
    watch: {
        value: function (newValue) {
            if (document.activeElement == this.$el) {
                return;
            }
            this.$el.innerHTML = newValue;
        },
    },
    methods: {
        onPaste(e) {
            e.preventDefault();
            e.stopPropagation();

            let paste = (e.clipboardData || window.clipboardData).getData('text/plain');

            paste = paste.replace(/\\x0D/gi, "\n");
            e.target.textContent += paste;
        },
    }
}
</script>
